import _ from 'lodash';
import moment from 'moment';
import {
  updateBasketTimeWanted,
  updateDuplicateAddress,
} from '../redux/actions/basket/checkout';
import { store } from '../redux/store';
import axiosInstance from '../services/axiosInceptor';
import { requestDelUserDelAddress } from '../services/user';
import {
  RequestBasketSubmit,
  RequestUpdateBasketTimeWanted,
  ResponseRestaurantCalendars,
} from '../types/olo-api';
import { DeliveryModeEnum, UserTypeEnum } from '../types/olo-api/olo-api.enums';
import { isLoginUser } from './auth';
import { CalendarTypeEnum, HoursListing } from './hoursListing';

const cardTypes: any = {
  amex: 'Amex',
  visa: 'Visa',
  discover: 'Discover',
  mastercard: 'Mastercard',
};

export function removePreviousAddresses(
  basketAddresses: any,
  deliveryAddress: any,
  basket: any,
  removeAll: any,
) {
  if (basketAddresses?.duplicated?.length > 0 && isLoginUser()) {
    let filterSavedAddress: number[] = [];

    if (removeAll === false) {
      filterSavedAddress = basketAddresses?.duplicated?.filter(
        (addressId: any) =>
          basket?.deliveryaddress?.id !== addressId &&
          deliveryAddress?.id !== addressId,
      );
    } else {
      filterSavedAddress = basketAddresses?.duplicated;
    }
    if (filterSavedAddress?.length > 0 && filterSavedAddress[0] !== null) {
      filterSavedAddress.forEach((id: any) => {
        requestDelUserDelAddress(id);
      });
      const remainingAddress = basketAddresses?.duplicated.filter(
        (addressId: any) => !filterSavedAddress.includes(addressId),
      );
      store.dispatch(updateDuplicateAddress(remainingAddress));
    }
  }
}
export function generateSubmitBasketPayload(
  formData: any,
  billingSchemes: any,
  deliverymode: string,
  authtoken: string,
  basket: any,
  basketAccessToken: any,
): RequestBasketSubmit {
  const billingSchemeStats = getBillingSchemesStats(billingSchemes);
  let paymentPayload: any = {};
  if (
    billingSchemeStats.selectedCreditCard === 1 ||
    billingSchemeStats.selectedGiftCard > 0
  ) {
    let billingaccounts: any = [];
    let tip = (basket && basket.tip) || 0;
    billingSchemes.forEach((account: any) => {
      if (account.selected) {
        let obj = {
          ...account,
        };
        if (account.billingaccountid) {
          obj.billingmethod = 'billingaccount';
        }

        if (tip > 0) {
          if (obj.amount >= tip) {
            obj.tipportion = tip;
            tip = 0;
          } else {
            obj.tipportion = obj.amount;
            tip = +(tip - obj.amount).toFixed(2);
          }
        }
        delete obj.selected;
        delete obj.localId;
        delete obj.balance;
        delete obj.alwaysVisible;
        billingaccounts.push(obj);
      }
    });
    paymentPayload = {
      billingaccounts: billingaccounts,
    };
  }

  let payload: RequestBasketSubmit = {
    id: basket.id,
    accessToken: basketAccessToken,
    usertype: UserTypeEnum.guest,
    guestoptin: formData.emailNotification,
    ...paymentPayload,
  };

  if (
    deliverymode === DeliveryModeEnum.curbside ||
    deliverymode === DeliveryModeEnum.pickup ||
    deliverymode === DeliveryModeEnum.dinein ||
    deliverymode === DeliveryModeEnum.dispatch
  ) {
    payload = {
      ...payload,
      firstname: formData.firstName,
      lastname: formData.lastName,
      emailaddress: formData.email,
      contactnumber: formData.phone,
      receivinguser: {
        firstname: formData.firstName,
        lastname: formData.lastName,
        emailaddress: formData.email,
        contactnumber: formData.phone,
      },
    };
  }

  if (isLoginUser()) {
    payload.usertype = UserTypeEnum.user;
    delete payload.guestoptin;
  }

  return payload;
}

export function formatCustomFields(customFields: any, formData: any) {
  let formatArray: any = [];

  customFields.forEach((field: any) => {
    let obj = {};

    if (field.label === 'Table Number' && formData.tableNumber !== '') {
      obj = {
        id: field.id,
        value: formData.tableNumber,
      };
    } else if (field.label === 'Model' && formData.vehicleModal !== '') {
      obj = {
        id: field.id,
        value: formData.vehicleModal,
      };
    } else if (field.label === 'Make' && formData.vehicleMake !== '') {
      obj = {
        id: field.id,
        value: formData.vehicleMake,
      };
    } else if (field.label === 'Color' && formData.vehicleColor !== '') {
      obj = {
        id: field.id,
        value: formData.vehicleColor,
      };
    }
    if (Object.keys(obj).length) {
      formatArray.push(obj);
    }
  });

  return formatArray;
}

const isTimeSame = (fTime: string, sTime: string): boolean => {
  return fTime.split(' ')[1] === sTime.split(' ')[1];
};

export function GetRestaurantHoursRange(
  hours: ResponseRestaurantCalendars,
  type: CalendarTypeEnum,
): HoursListing[] {
  const selectedStoreHours = hours?.calendar?.find((x) => x.type === type);
  let newHoursArray: HoursListing[] = [];
  if (selectedStoreHours) {
    selectedStoreHours &&
      selectedStoreHours.ranges.forEach((item, index) => {
        newHoursArray.push({
          label: item.weekday.substring(0, 1),
          start: item.start,
          end: item.end,
          isOpenAllDay: isTimeSame(item.start, item.end),
        });
      });
  }
  return newHoursArray;
}

export const addToDate = (date: any, count: any, unit: any) =>
  moment(date || currentDateTime).add(count, unit);

export const getDateDiff = (date1: any, date2: any, unit: any) =>
  moment(date1).diff(date2, unit);

const currentDateTime = new Date();

export const formatDateTime = (
  date: any,
  outFormat: any,
  inputFormat?: any,
) => {
  if (inputFormat) {
    return moment(date ?? currentDateTime, inputFormat).format(outFormat);
  }
  return moment(date ?? currentDateTime).format(outFormat);
};

export const strToDate = (date: any, format: any) => {
  if (format) {
    return moment(date, format);
  }
  return moment(date);
};

export const finalizedTimeSlots = async ({
  restaurantHours = [],
  selectedDayIndex = 0,
  basket = {},
  isClickedByUser,
}: any) => {
  let slots;
  let dayIndex = selectedDayIndex;
  let selectedTime;
  const { timewanted } = basket || {};

  // For first day start slots with the earliest ready time.
  const isEarliestDay = dayIndex === 0;
  try {
    const availableSlots = await getAvailableTimeSlots(
      basket,
      restaurantHours,
      isClickedByUser,
    );
    slots = _.map(availableSlots.times, 'time');
    if (slots?.length > 0 && !timewanted && isEarliestDay) {
      dayIndex = 0;
      selectedTime = slots?.[0];
      const payload = createTimeWantedPayload(selectedTime);
      basket && store.dispatch(updateBasketTimeWanted(basket?.id, payload));
    } else {
      if (isEarliestDay && isClickedByUser === false) {
        const roundedEarlyTime = moment(
          basket.earliestreadytime,
          'YYYYMMDD HH:mm',
        );
        const roundedMinutes = Math.ceil(roundedEarlyTime.minute() / 15) * 15;
        roundedEarlyTime.set('minute', roundedMinutes);
        roundedEarlyTime.add(15, 'minute');
        const formattedEarlyTime = roundedEarlyTime.format('YYYYMMDD HH:mm');

        const payload = createTimeWantedPayload(formattedEarlyTime);
        basket && store.dispatch(updateBasketTimeWanted(basket?.id, payload));
      }
    }
  } catch (error) {
    console.error('Error fetching time slots:', error);
    // Handle error as needed
  }
  return {
    slots,
    dayIndex,
    selectedTime,
  };
};

export async function getAvailableTimeSlots(
  basket: any,
  restaurantHours: any,
  isClickedByUser: any,
) {
  try {
    let currentTime = moment();
    let closeAt = moment(restaurantHours[0]?.end, 'YYYYMMDD HH:mm');
    let isRestaurantOpen = true;
    if (currentTime.isAfter(closeAt)) {
      isRestaurantOpen = false;
      return [];
    }

    let response = await apiCallGetAvailableTimes(basket, restaurantHours);
    if (response?.data.times.length === 0 || !isRestaurantOpen) {
      const noSlotsAvailable = true;
      response = await apiCallGetAvailableTimes(
        basket,
        restaurantHours,
        noSlotsAvailable,
        isClickedByUser,
      );
    }

    return response?.data || [];
  } catch (error) {
    console.error('Error fetching available time slots:', error);
    throw error;
  }
}

const apiCallGetAvailableTimes = async (
  basket: any,
  restaurantHours: any,
  noSlotsAvailable?: any,
  isClickedByUser?: any,
) => {
  try {
    let futureString = '';
    if (noSlotsAvailable) {
      const momentObject = moment(basket.earliestreadytime, 'YYYYMMDD HH:mm');
      const futureMoment = momentObject.add(12, 'hours');
      futureString = futureMoment.format('YYYYMMDD HH:mm');
    }

    let openingTime;
    let currentTime = moment();
    let openAt = moment(restaurantHours[0]?.start, 'YYYYMMDD HH:mm');
    if (currentTime.isAfter(openAt)) {
      openingTime = moment().add(3, 'minute').format('YYYYMMDD HH:mm');
    } else {
      openingTime = restaurantHours[0]?.start;
    }

    let restaurant_id =
      store?.getState()?.restaurantInfoReducer?.restaurant?.id || null;
    const url = process.env.REACT_APP_OLO_API || '';
    let times = await axiosInstance.get(
      `${url}/restaurants/${restaurant_id}/availabletimes`,
      {
        params: {
          basketid: basket.id,
          starttime:
            noSlotsAvailable === true && isClickedByUser
              ? basket.earliestreadytime
              : openingTime,
          endtime:
            noSlotsAvailable === true && isClickedByUser
              ? futureString
              : restaurantHours[0]?.end,
        },
      },
    );
    return times;
  } catch (error) {
    console.error('Error fetching available time slots:', error);
  }
};

export function createTimeWantedPayload(time: string) {
  const date = moment(time, 'YYYYMMDD HH:mm');
  const payload: RequestUpdateBasketTimeWanted = {
    ismanualfire: false,
    year: date.year(),
    month: date.month() + 1,
    day: date.date(),
    hour: date.hour(),
    minute: date.minute(),
  };
  return payload;
}

export function getUniqueId() {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

export function getBillingSchemesStats(billingSchemes: any) {
  let billingSchemeStats: any = {
    creditCard: 0,
    giftCard: 0,
    selectedCreditCard: 0,
    selectedGiftCard: 0,
    savedCards: 0,
  };

  billingSchemes.forEach((account: any) => {
    billingSchemeStats = {
      creditCard:
        account.billingmethod === 'creditcard'
          ? billingSchemeStats.creditCard + 1
          : billingSchemeStats.creditCard,
      giftCard:
        account.billingmethod === 'storedvalue'
          ? billingSchemeStats.giftCard + 1
          : billingSchemeStats.giftCard,
      selectedCreditCard:
        account.billingmethod === 'creditcard' && account.selected
          ? billingSchemeStats.selectedCreditCard + 1
          : billingSchemeStats.selectedCreditCard,
      selectedGiftCard:
        account.billingmethod === 'storedvalue' && account.selected
          ? billingSchemeStats.selectedGiftCard + 1
          : billingSchemeStats.selectedGiftCard,
      savedCards:
        account.billingmethod === 'creditcard' && account.savedCard
          ? billingSchemeStats.savedCards + 1
          : billingSchemeStats.savedCards,
    };
  });

  return billingSchemeStats;
}

export function getCreditCardObj(cardDetails: any, billingSchemes: any) {
  const billingSchemeStats = getBillingSchemesStats(billingSchemes);
  let selected = billingSchemeStats.selectedCreditCard < 1;

  let cardObj: any = [
    {
      localId: getUniqueId(),
      selected: true,
      billingmethod: 'creditcard',
      amount: 0,
      tipportion: 0.0,
      expiryyear: cardDetails.exp_year,
      expirymonth: cardDetails.exp_month,
      zip: cardDetails.postal_code,
      saveonfile: cardDetails.savecard,
      alwaysVisible: false,
    },
  ];

  return cardObj;
}

export function getGiftCardObj(
  balanceResponse: any,
  billingSchemeId: any,
  body: any,
  billingSchemes: any,
) {
  const billingSchemeStats = getBillingSchemesStats(billingSchemes);

  let selected = billingSchemeStats.selectedGiftCard < 4;

  let cardObj: any = [
    {
      localId: getUniqueId(),
      selected: selected,
      billingmethod: 'storedvalue',
      balance: balanceResponse.balance,
      amount: 0,
      tipportion: 0.0,
      saveOnFile: true,
      billingschemeid: billingSchemeId,
      billingfields: [
        {
          name: 'number',
          value: body.cardnumber,
        },
      ],
      alwaysVisible: false,
    },
  ];

  if (body.pin && body.pin !== '') {
    cardObj[0].billingfields.push({
      name: 'pin',
      value: body.pin,
    });
  }

  return cardObj;
}

export function updatePaymentCardsAmount(billingSchemes: any, basket: any) {
  const billingSchemeStats = getBillingSchemesStats(billingSchemes);
  let total = basket && basket.total ? basket.total : 0;

  billingSchemes.sort((a: any, b: any) =>
    b.billingmethod > a.billingmethod
      ? 1
      : a.billingmethod > b.billingmethod
      ? -1
      : 0,
  );

  billingSchemes.sort((a: any, b: any) =>
    a.balance > b.balance ? 1 : b.balance > a.balance ? -1 : 0,
  );

  billingSchemes = billingSchemes.map((account: any) => {
    if (account.selected) {
      if (account.billingmethod === 'storedvalue') {
        let giftCardAmount: any =
          account.balance >= total ? total : account.balance;
        total = (total - giftCardAmount).toFixed(2);
        account.amount = parseFloat(giftCardAmount);
      } else if (account.billingmethod === 'creditcard') {
        account.amount = parseFloat(total);
      }
    } else {
      account.amount = 0;
    }
    return account;
  });
  return billingSchemes;
}

export function remainingAmount(basket: any, billingSchemes: any) {
  if (basket && billingSchemes) {
    let amountSelected = billingSchemes.reduce((sum: any, account: any) => {
      if (account.selected) {
        sum = sum + account.amount;
      }
      return sum;
    }, 0);

    amountSelected = amountSelected.toFixed(2);
    amountSelected = parseFloat(amountSelected);

    let remainingAmount = (basket?.total - amountSelected).toFixed(2);

    if (remainingAmount !== 'NAN') {
      return remainingAmount;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
}
